/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Container from "../components/container"
import SEO from "../components/seo"
import ShareButtons from "../components/share-buttons"

export default function Template({ data }) {
  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark
  const postLink = encodeURI(data.site.siteMetadata.siteUrl + frontmatter.slug)
  const image = frontmatter.featured
    ? frontmatter.featured.childImageSharp.resize.src
    : null
  return (
    <Layout
      title={frontmatter.title}
      newsletter={frontmatter.newsletter ? true : false}
      liste={frontmatter.liste}
    >
      <SEO title={frontmatter.title} image={image} />
      <Container sx={{ maxWidth: 690, p: [4, null] }}>
        <div dangerouslySetInnerHTML={{ __html: html }} />
        {frontmatter.share && (
          <ShareButtons title={frontmatter.title} url={postLink} />
        )}
      </Container>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        slug
        title
        featured {
          childImageSharp {
            resize(width: 1200) {
              src
              height
              width
            }
          }
        }
        newsletter
        share
        liste
      }
    }
  }
`
